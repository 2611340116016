// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, ResolveLinks, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./OZ8eg0hWP-0.js";
import BigNewsItem from "./Xql3FiNSk";
const BigNewsItemFonts = getFonts(BigNewsItem);
const BigNewsItemControls = getPropertyControls(BigNewsItem);

const cycleOrder = ["PZC6glzkc", "VCGgNpIUO"];

const serializationHash = "framer-P1mZG"

const variantClassNames = {PZC6glzkc: "framer-v-1bu5z0m", VCGgNpIUO: "framer-v-8m1ew2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {"HeroNews-Rockstars": "Fk4_uHvzR", Smaller: "n60Zw5esL"}

const humanReadableVariantMap = {"Variant 1": "PZC6glzkc", "Variant 2": "VCGgNpIUO"}

const getProps = ({height, id, variant1, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PZC6glzkc", vr9ih_pKK: humanReadableEnumMap[variant1] ?? variant1 ?? props.vr9ih_pKK ?? "Fk4_uHvzR"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;variant1?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, vr9ih_pKK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PZC6glzkc", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1bu5z0m", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PZC6glzkc"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({VCGgNpIUO: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ResolveLinks links={[{href: {webPageId: "x2ZE1YTY3"}, implicitPathVariables: undefined}, {href: {webPageId: "x2ZE1YTY3"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider width={"630px"}><motion.div className={"framer-1260ush-container"} layoutDependency={layoutDependency} layoutId={"fK8KfzWIr-container"}><BigNewsItem AnS4O2Evj={21} height={"100%"} id={"fK8KfzWIr"} iqXK4q134={"flex-start"} jwAyyQQS1={"Púls Media: Rokkstjörnur í Auglýsingatækni"} layoutId={"fK8KfzWIr"} lJ8TzLmSL NNoVE9bw3={"flex-start"} style={{height: "100%", width: "100%"}} sW5VGgQ4j={"Connect your site to the most popular apps out there."} txqSXoZAH={"0px"} UEHqg2DCZ={resolvedLinks[0]} variant={vr9ih_pKK} VoqsHrYLI={"column"} width={"100%"} {...addPropertyOverrides({VCGgNpIUO: {jwAyyQQS1: "Planner", UEHqg2DCZ: resolvedLinks[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-P1mZG.framer-psvqfl, .framer-P1mZG .framer-psvqfl { display: block; }", ".framer-P1mZG.framer-1bu5z0m { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-P1mZG .framer-1260ush-container { flex: none; height: 574px; position: relative; width: 630px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-P1mZG.framer-1bu5z0m { gap: 0px; } .framer-P1mZG.framer-1bu5z0m > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-P1mZG.framer-1bu5z0m > :first-child { margin-left: 0px; } .framer-P1mZG.framer-1bu5z0m > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 574
 * @framerIntrinsicWidth 630
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"VCGgNpIUO":{"layout":["auto","auto"]}}}
 * @framerVariables {"vr9ih_pKK":"variant1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOZ8eg0hWP: React.ComponentType<Props> = withCSS(Component, css, "framer-P1mZG") as typeof Component;
export default FramerOZ8eg0hWP;

FramerOZ8eg0hWP.displayName = "NewsItem";

FramerOZ8eg0hWP.defaultProps = {height: 574, width: 630};

addPropertyControls(FramerOZ8eg0hWP, {variant: {options: ["PZC6glzkc", "VCGgNpIUO"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, vr9ih_pKK: BigNewsItemControls?.["variant"] && {...BigNewsItemControls["variant"], defaultValue: "Fk4_uHvzR", description: undefined, hidden: undefined, title: "Variant"}} as any)

addFonts(FramerOZ8eg0hWP, [{explicitInter: true, fonts: []}, ...BigNewsItemFonts], {supportsExplicitInterCodegen: true})